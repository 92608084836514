
  import Events from '@/events/Events';

  export default {
    props: {
      element: {
        type: Object,
        require: true,
        default: () => ({}),
      },
      elementType: {
        type: String,
        required: true,
        default: 'article',
      },
    },
    data() {
      return {
        elementHashtags: '',
        currentElementType: {},
        elementTypes: [
          {
            title: 'Artikel',
            urlName: 'articles-category-article',
          },
          {
            title: 'Artikel',
            urlName: 'organization-articles-category-article',
          },
          {
            title: 'Event',
            urlName: 'events-category-event',
          },
          {
            title: 'Wiki',
            urlName: 'wikis-slug',
          },
        ],
      };
    },
    created() {
      const articleTags = [];
      if (this.element.article_tags && this.element.article_tags.length > 0) {
        this.element.article_tags.forEach((tag) => {
          if (tag.slug) {
            articleTags.push(tag.slug);
          }
        });
        this.elementHashtags = articleTags.toString();
      }
      if (this.element.relation_tags && this.element.relation_tags.length > 0) {
        this.elementHashtags = this.element.relation_tags;
      }
      if (
        this.elementType === 'article' &&
        this.element &&
        this.element.scope &&
        this.element.scope === process.env.SCOPE_ORGANIZATION
      ) {
        this.currentElementType = this.elementTypes[1];
      } else if (this.elementType === 'event') {
        this.currentElementType = this.elementTypes[2];
      } else if (this.elementType === 'wiki') {
        this.currentElementType = this.elementTypes[3];
      } else {
        this.currentElementType = this.elementTypes[0];
      }
    },
    methods: {
      triggerTracking(channel) {
        const eventTypeMap = {
          article: 'ArticlesShared',
          wiki: 'WikisShared',
        };

        const eventType = eventTypeMap[this.elementType] || 'EventsShared';
        const idKey =
          this.elementType === 'article'
            ? 'articleId'
            : this.elementType === 'wiki'
            ? 'wikiId'
            : 'eventId';

        this.$emitWithClientData(Events[eventType], {
          [idKey]: this.element.id,
          channel: channel,
        });
      },
      openShareBox(refName, channel) {
        this.triggerTracking(channel);
        if (!this.$refs[refName]) return;
        const ref = this.$refs[refName];
        if (ref.value === null) return;
        clearInterval(ref.popupInterval);
        ref.popupWindow = undefined;
      },
      getShareUrl(network) {
        return (
          process.env.NUXT_ENV_BASE_URL +
          this.localePath({
            name: this.currentElementType.urlName,
            params: {
              slug: this.element.slug,
            },
            query: {
              utm_source: 'pb_sharing',
              utm_medium: network,
            },
          })
        );
      },
    },
  };
